<template>
     <!-- Start Our Solutions Area -->
    <section class="solutions-area ptb-80">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">What We Offer</span>
                <h2>Our Featured Solutions</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="solutions-box">
                        <div class="icon">
                            <i class="bx bx-landscape"></i>
                            <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                        </div>

                        <h3><a href="#">Programmatic Advertising</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="solutions-box">
                        <div class="icon">
                            <i class="bx bx-bar-chart-alt"></i>
                            <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                        </div>

                        <h3><a href="#">Strategy & Research</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="solutions-box">
                        <div class="icon">
                            <i class="bx bx-devices"></i>
                            <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                        </div>

                        <h3><a href="#">Design & Development</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-6">
                    <div class="solutions-box">
                        <div class="icon">
                            <i class="bx bx-chalkboard"></i>
                            <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                        </div>

                        <h3><a href="#">Branding & Marketing</a></h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="shape9 wow fadeInLeft slow" v-wow><img src="../../../assets/img/agency-image/shape1.png" alt="image"></div>
    </section>
    <!-- End Our Solutions Area -->
</template>

<script>
export default {
    name: 'Solutions'
}
</script>