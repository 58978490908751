<template>
    <div id="services">
        <!-- Start Page Title -->
      
        <!-- End Page Title -->

        <!-- Start Services Area -->
        <div class="agency-services-area ptb-80">
            <div class="container  ptb-80">
                <div class="section-title">
                <h2>Services</h2>
                <div class="bar"></div>
            </div>
                <div class="row">
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services1.jpg" alt="image">

                            <div class="content">
                                <h3><router-link to="/#frontend">Frontend Development</router-link></h3>
								<router-link to="/#frontend" class="read-more-btn">Read More <feather type="plus-circle"></feather></router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services2.jpg" alt="image">

                            <div class="content">
                                <h3><router-link to="/#ecommerce">Ecommerce Development</router-link></h3>
								<router-link to="/#ecommerce" class="read-more-btn">Read More <feather type="plus-circle"></feather></router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services3.jpg" alt="image">

                            <div class="content">
                                <h3><router-link to="/#webapplication">Web Development</router-link></h3>
								<router-link to="/#webapplication" class="read-more-btn">Read More <feather type="plus-circle"></feather></router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services4.jpg" alt="image">

                            <div class="content">
                                <h3><router-link to="/#mobile">Mobile App Developments</router-link></h3>
								<router-link to="/#mobile" class="read-more-btn">Read More <feather type="plus-circle"></feather></router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services5.jpg" alt="image">

                            <div class="content">
                                <h3><router-link to="/#wordpress">Wordpress Development</router-link></h3>
								<router-link to="/#wordpress" class="read-more-btn">Read More <feather type="plus-circle"></feather></router-link>
                            </div>
                        </div>
                    </div>
                    
                    <!-- <div class="col-lg-4 col-sm-6 col-md-6">
                        <div class="agency-services-box">
                            <img src="../../../assets/img/agency-image/services-img6.jpg" alt="image">

                            <div class="content">
                                <h3><a href="#">Data Analysts</a></h3>
								<a href="#" class="read-more-btn">Read More <feather type="plus-circle"></feather></a>
                            </div>
                        </div>
                    </div>-->
                </div>
            </div>

			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
            <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Services Area -->
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>