<template>
    <!-- Start Repair Why Choose Us Area -->
    <section class="repair-why-choose-us ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>Our Featured Solutions</h2>
                <div class="bar"></div>
                <p>With time-tested methods and latest technology expertise, we're building a cocoon of intelligent services through our featured solutions.</p>
            </div>
            
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-box">
                        <div class="icon">
                           <img src="../../../assets/img/features-details-image/icon-1.png" alt="image">
                        </div>
                        <h3>Chat Bots & IVR</h3>
                        <p>We work through the best of both worlds, from human interface to AI-enabled bots, as per the requirement and need of the business.</p>
                        <a href="#"><feather type="arrow-right"></feather></a>

                        <div class="back-icon">
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-box">
                        <div class="icon">
                            <img src="../../../assets/img/features-details-image/icon-2.png" alt="image">
                        </div>
                        <h3>Devops & Cloud</h3>
                        <p>For a speedy and agile functionality, we maneuver on both the applications to provide the brands with better solutions and reasonable infrastructure.</p>
                        <a href="#"><feather type="arrow-right"></feather></a>

                        <div class="back-icon">
                            
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-repair-box">
                        <div class="icon">
                           <img src="../../../assets/img/features-details-image/icon-3.png" alt="image">
                        </div>
                        <h3>IOT Solutions</h3>
                        <p>From smart logistics to erudite utilities to asset tracking, we bring and work on the mosaic of IOTs to totally transform your business.</p>
                        <a href="#"><feather type="arrow-right"></feather></a>

                        <div class="back-icon">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Repair Why Choose Us Area -->
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>