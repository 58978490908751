<template>
    <!-- Start Repair About Area -->
    <section class="repair-about-area ptb-80">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="repair-about-content">
                        <span class="sub-title">About Us</span>
                        <h2>We have 35 years of experience in repair services</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                        <ul>
                            <li><span><feather type="check"></feather> Experienced Professionals</span></li>
                            <li><span><feather type="check"></feather> Expert Technical Skills</span></li>
                            <li><span><feather type="check"></feather> Excellent Reputation</span></li>
                            <li><span><feather type="check"></feather> Friendly Service</span></li>
                            <li><span><feather type="check"></feather> Trustworthy See Reviews</span></li>
                            <li><span><feather type="check"></feather> Affordable Diagnosis</span></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="repair-about-image">
                        <img src="../../../assets/img/about1.jpg" class="wow fadeInDown" alt="image">
                        <img src="../../../assets/img/about2.jpg" class="wow zoomIn" alt="image">
                        <img src="../../../assets/img/about3.jpg" class="wow fadeInUp" alt="image">
                        <img src="../../../assets/img/about-shape1.png" alt="image">
                        <img src="../../../assets/img/about-shape2.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Repair About Area -->
</template>

<script>
export default {
    name: 'About'
}
</script>