<template>
    <!-- Start Pricing Area -->
    <section class="pricing-area ptb-80 pt-0">
        <div class="container">
            <div class="section-title">
                <h2>Our Pricing Plan</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            
            <div class="tab pricing-tab bg-color">
                <b-card no-body>
                    <b-tabs 
                        content-class="mt-3" 
                        align="center"
                        active-nav-item-class="current"
                    >
                        <b-tab title="Monthly Plan" active>
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="pricing-box">
                                            <div class="pricing-header">
                                                <h3>Free</h3>
                                                <p>Get your business up <br>and running</p>
                                            </div>

                                            <div class="price">
                                                $0 <span>/m</span>
                                            </div>

                                            <div class="buy-btn">
                                                <a href="#" class="btn btn-primary">Get Started Free</a>
                                            </div>

                                            <ul class="pricing-features">
                                                <li><feather type='check'></feather> Drag & Drop Builder</li>
                                                <li><feather type='check'></feather> Lead Generation & Sales</li>
                                                <li><feather type='check'></feather> Boot & Digital Assistants</li>
                                                <li><feather type='check'></feather> Customer Service</li>
                                                <li><feather type='check'></feather> Up to 1000 Subscribers</li>
                                                <li><feather type='check'></feather> Unlimited Broadcasts</li>
                                                <li><feather type='check'></feather> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="pricing-box">
                                            <div class="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Get your business up <br>and running</p>
                                            </div>

                                            <div class="price">
                                                $149 <span>/m</span>
                                            </div>

                                            <div class="buy-btn">
                                                <a href="#" class="btn btn-primary">Start 3 Days Free Trial</a>
                                            </div>

                                            <ul class="pricing-features">
                                                <li><feather type='check'></feather> Drag & Drop Builder</li>
                                                <li><feather type='check'></feather> Lead Generation & Sales</li>
                                                <li><feather type='check'></feather> Boot & Digital Assistants</li>
                                                <li><feather type='check'></feather> Customer Service</li>
                                                <li><feather type='check'></feather> Up to 3300 Subscribers</li>
                                                <li><feather type='check'></feather> Unlimited Broadcasts</li>
                                                <li><feather type='check'></feather> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                                        <div class="pricing-box">
                                            <div class="pricing-header">
                                                <h3>Premium</h3>
                                                <p>Get your business up <br>and running</p>
                                            </div>

                                            <div class="price">
                                                $179 <span>/m</span>
                                            </div>

                                            <div class="buy-btn">
                                                <a href="#" class="btn btn-primary">Start 6 Days Free Trial</a>
                                            </div>

                                            <ul class="pricing-features">
                                                <li><feather type='check'></feather> Drag & Drop Builder</li>
                                                <li><feather type='check'></feather> Lead Generation & Sales</li>
                                                <li><feather type='check'></feather> Boot & Digital Assistants</li>
                                                <li><feather type='check'></feather> Customer Service</li>
                                                <li><feather type='check'></feather> Up to 10000 Subscribers</li>
                                                <li><feather type='check'></feather> Unlimited Broadcasts</li>
                                                <li><feather type='check'></feather> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab title="Yearly Plan" lazy> 
                            <div class="tabs_item">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="pricing-box">
                                            <div class="pricing-header">
                                                <h3>Free</h3>
                                                <p>Get your business up <br>and running</p>
                                            </div>

                                            <div class="price">
                                                $0 <span>/y</span>
                                            </div>

                                            <div class="buy-btn">
                                                <a href="#" class="btn btn-primary">Get Started Free</a>
                                            </div>

                                            <ul class="pricing-features">
                                                <li><feather type='check'></feather> Drag & Drop Builder</li>
                                                <li><feather type='check'></feather> Lead Generation & Sales</li>
                                                <li><feather type='check'></feather> Boot & Digital Assistants</li>
                                                <li><feather type='check'></feather> Customer Service</li>
                                                <li><feather type='check'></feather> Up to 1000 Subscribers</li>
                                                <li><feather type='check'></feather> Unlimited Broadcasts</li>
                                                <li><feather type='check'></feather> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6">
                                        <div class="pricing-box">
                                            <div class="pricing-header">
                                                <h3>Pro</h3>
                                                <p>Get your business up <br>and running</p>
                                            </div>

                                            <div class="price">
                                                $249 <span>/y</span>
                                            </div>

                                            <div class="buy-btn">
                                                <a href="#" class="btn btn-primary">Start 3 Days Free Trial</a>
                                            </div>

                                            <ul class="pricing-features">
                                                <li><feather type='check'></feather> Drag & Drop Builder</li>
                                                <li><feather type='check'></feather> Lead Generation & Sales</li>
                                                <li><feather type='check'></feather> Boot & Digital Assistants</li>
                                                <li><feather type='check'></feather> Customer Service</li>
                                                <li><feather type='check'></feather> Up to 3300 Subscribers</li>
                                                <li><feather type='check'></feather> Unlimited Broadcasts</li>
                                                <li><feather type='check'></feather> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                                        <div class="pricing-box">
                                            <div class="pricing-header">
                                                <h3>Premium</h3>
                                                <p>Get your business up <br>and running</p>
                                            </div>

                                            <div class="price">
                                                $279 <span>/y</span>
                                            </div>

                                            <div class="buy-btn">
                                                <a href="#" class="btn btn-primary">Start 6 Days Free Trial</a>
                                            </div>

                                            <ul class="pricing-features">
                                                <li><feather type='check'></feather> Drag & Drop Builder</li>
                                                <li><feather type='check'></feather> Lead Generation & Sales</li>
                                                <li><feather type='check'></feather> Boot & Digital Assistants</li>
                                                <li><feather type='check'></feather> Customer Service</li>
                                                <li><feather type='check'></feather> Up to 10000 Subscribers</li>
                                                <li><feather type='check'></feather> Unlimited Broadcasts</li>
                                                <li><feather type='check'></feather> Landing Pages & Web Widgets</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>

        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </section>
    <!-- End Pricing Area -->
</template>

<script>
export default {
    name: 'Pricing'
}
</script>