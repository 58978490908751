<template>
    <!-- Start Partner Area -->
    <div class="partner-area partner-section ptb-80 pt-0">
        <div class="container">
            <h5>More that 1.5 million businesses and organizations use StartP</h5>

            <div class="partner-inner">
                <div class="row">
                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-1.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover1.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-2.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover2.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-3.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover3.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-4.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover4.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-5.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover5.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-6.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover6.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-7.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover7.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-8.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover8.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-9.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover9.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-10.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover10.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-11.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover11.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-12.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover12.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-13.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover13.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-14.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover14.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-15.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover15.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-16.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover16.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-17.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover17.png" alt="partner">
                        </a>
                    </div>

                    <div class="col-lg-2 col-md-3 col-6 col-sm-4">
                        <a href="#">
                            <img src="../../../assets/img/partner-img/partner-18.png" alt="partner">
                            <img src="../../../assets/img/partner-img/partner-hover18.png" alt="partner">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Partner Area -->
</template>

<script>
export default {
    name: 'Partner'
}
</script>