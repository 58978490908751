<template>
    <!-- Start Repair Why Choose Us Area -->
    <section class="repair-why-choose-us ptb-80 pb-0">
        <div class="container">
            <div class="section-title">
                <h2>Why We Are Best From Others</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-box">
                        <div class="icon">
                            <i class="flaticon-handshake"></i>
                        </div>
                        <h3>Quick Repair Process</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#"><feather type="arrow-right"></feather></a>

                        <div class="back-icon">
                            <i class="flaticon-handshake"></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-box">
                        <div class="icon">
                            <i class="flaticon-repair"></i>
                        </div>
                        <h3>Free Diagnostics</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#"><feather type="arrow-right"></feather></a>

                        <div class="back-icon">
                            <i class="flaticon-repair"></i>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-repair-box">
                        <div class="icon">
                            <i class="flaticon-delivery-truck"></i>
                        </div>
                        <h3>Fast Delivery</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        <a href="#"><feather type="arrow-right"></feather></a>

                        <div class="back-icon">
                            <i class="flaticon-delivery-truck"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Repair Why Choose Us Area -->
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>