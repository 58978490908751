<template>
    <!-- Start Why Choose US Area -->
        <section class="why-choose-us ptb-80"  id="webapplication">
        
        <div class="container ptb-80"  id="webapplication">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <h2>Web Application Development</h2>
                        <div class="bar"></div>
                        <p>There is so much that goes into a web application. The dynamic suite of services and a thorough knowledge of web application development, we take care of all the technicalities, from prototyping the applications to interface design, from analyzing the business requirement and market condition to programming the servers. We go ahead and provide the technical support after the launch, we're an ally to all our partners, forever!</p>
					</div>
					
                    <div class="why-choose-us-image">
                        <img src="../../../assets/img/why-choose-us-image/image-3.png" class="wow fadeInLeft" alt="image">
                        <img src="../../../assets/img/services-left-image/cercle-shape.png" class="rotateme" alt="image">
                        <img src="../../../assets/img/why-choose-us-image/image-3.png" class="main-pic wow fadeInUp" alt="image">
					</div>
				</div>
				
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/laravel-icon.png" alt="image">
								</div>
                                <h3>Laravel</h3>
                                <p>Laravel is the Most Secured PHP framework. We used it for constructing everything, from small to enterprise-level applications.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/cakephp-icon.png" alt="image">
								</div>
                                <h3>CakePHP</h3>
                                <p>CakePHP is an open-source web framework which follows MVC approach. We are successfully using it in many applications like HMS, HRMS.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/codeigniter-icon.png" alt="image">
								</div>
                                <h3>Codeigniter</h3>
                                <p>CodeIgniter is a rapid development web framework. We mostly use this framework for small applications and dashboards.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/custom-icon.png" alt="image">
								</div>
                                <h3>Custom</h3>
                                <p>Other than the Laravel, CakePHP and Codeigniter we have worked on many custom PHP frameworks. Our team has an expertise on PHP.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</section>
    
    <!-- End Why Choose US Area -->
</template>

<script>
	export default {
		name: 'WebApplication'
	}
</script>