<template>
    <!-- Start Why Choose US Area -->
    <section class="why-choose-us ptb-80"  id="mobile">
        <div class="container" >
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <h2>Mobile Development</h2>
                        <div class="bar"></div>
                        <p>The mobile-apps demand is an ever-increasing aspect of a technology-driven market. Around 80% of smartphone market share holds by Android, and 60% of tablet market share holds by iOS. TechnoCravers are a team that has a ridiculously strong expertness on Linux and Mac OS, with a spectrum of open-source and paid frameworks to develop any mobile application in panache.</p>
					</div>
					
                    <div class="why-choose-us-image">
                        <img src="../../../assets/img/why-choose-us-image/man-img1.png" class="wow fadeInLeft" alt="image">
                        <img src="../../../assets/img/services-left-image/cercle-shape.png" class="rotateme" alt="image">
                        <img src="../../../assets/img/why-choose-us-image/man-img1.png" class="main-pic wow fadeInUp" alt="image">
					</div>
				</div>
				
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/ionic-icon.png" alt="image">
								</div>
                                <h3>Ionic / Cordova</h3>
                                <p>Ionic build amazing cross platform apps with one shared code base. We use this as a primary tool to build mobile apps.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/ios-icon.png" alt="image">
								</div>
                                <h3>iOS Application</h3>
                                <p>iOS is a mobile operating system created and developed by Apple Inc. If you are looking for iOS native apps. Hire us now!</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/android-icon.png" alt="image">
								</div>
                                <h3>Android Application</h3>
                                <p>Android is one of the most popular mobile operating system. Looking to develop an Android Native App? You have come to the right place. Contact us now!</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/react-icon.png" alt="image">
								</div>
                                <h3>React Native</h3>
                                <p>React Native is an open-source mobile application framework created by Facebook. Our team has a hands-on experience on this.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        </section>
            
    <!-- End Why Choose US Area -->
</template>

<script>
	export default {
		name: 'Mobile'
	}
</script>