var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"repair-why-choose-us ptb-80 pb-0"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-6"},[_c('div',{staticClass:"single-repair-box"},[_vm._m(1),_c('h3',[_vm._v("Quick Repair Process")]),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"arrow-right"}})],1),_vm._m(2)])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-6"},[_c('div',{staticClass:"single-repair-box"},[_vm._m(3),_c('h3',[_vm._v("Free Diagnostics")]),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"arrow-right"}})],1),_vm._m(4)])]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3"},[_c('div',{staticClass:"single-repair-box"},[_vm._m(5),_c('h3',[_vm._v("Fast Delivery")]),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"arrow-right"}})],1),_vm._m(6)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Why We Are Best From Others")]),_c('div',{staticClass:"bar"}),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"flaticon-handshake"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-icon"},[_c('i',{staticClass:"flaticon-handshake"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"flaticon-repair"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-icon"},[_c('i',{staticClass:"flaticon-repair"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"flaticon-delivery-truck"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"back-icon"},[_c('i',{staticClass:"flaticon-delivery-truck"})])
}]

export { render, staticRenderFns }