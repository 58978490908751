var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',{staticClass:"shop-details-area ptb-80"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"products-details-image"},[_c('DetailsImage')],1)]),_c('div',{staticClass:"col-lg-7"},[_c('div',{staticClass:"products-details"},[_c('h3',[_vm._v("Wood Pencil")]),_vm._m(1),_vm._m(2),_c('p',[_vm._v("There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.")]),_vm._m(3),_c('AddToCartDetails'),_vm._m(4),_c('div',{staticClass:"products-share-social"},[_c('span',[_vm._v("Share:")]),_c('ul',[_c('li',[_c('a',{staticClass:"facebook",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"facebook"}})],1)]),_c('li',[_c('a',{staticClass:"twitter",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"twitter"}})],1)]),_c('li',[_c('a',{staticClass:"linkedin",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"linkedin"}})],1)]),_c('li',[_c('a',{staticClass:"instagram",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"instagram"}})],1)])])])],1)]),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Products Details")])])])]),_c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../../assets/img/shape1.png"),"alt":"shape"}})]),_c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../../../assets/img/shape3.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape5"},[_c('img',{attrs:{"src":require("../../../assets/img/shape5.png"),"alt":"shape"}})]),_c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"price"},[_c('span',[_vm._v("$200.00")]),_vm._v(" $199.00 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"rating"},[_c('li',[_c('i',{staticClass:"flaticon-star-1"})]),_c('li',[_c('i',{staticClass:"flaticon-star-1"})]),_c('li',[_c('i',{staticClass:"flaticon-star-1"})]),_c('li',[_c('i',{staticClass:"flaticon-star-1"})]),_c('li',[_c('i',{staticClass:"flaticon-star-1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"availability"},[_vm._v(" Availability: "),_c('span',[_vm._v("In Stock")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-payment-options"},[_c('span',[_vm._v("Guaranteed safe checkout:")]),_c('div',{staticClass:"payment-methods"},[_c('img',{attrs:{"src":require("../../../assets/img/payment-image/1.svg"),"alt":"image"}}),_c('img',{attrs:{"src":require("../../../assets/img/payment-image/2.svg"),"alt":"image"}}),_c('img',{attrs:{"src":require("../../../assets/img/payment-image/3.svg"),"alt":"image"}}),_c('img',{attrs:{"src":require("../../../assets/img/payment-image/4.svg"),"alt":"image"}}),_c('img',{attrs:{"src":require("../../../assets/img/payment-image/5.svg"),"alt":"image"}}),_c('img',{attrs:{"src":require("../../../assets/img/payment-image/6.svg"),"alt":"image"}}),_c('img',{attrs:{"src":require("../../../assets/img/payment-image/7.svg"),"alt":"image"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"products-details-tabs"},[_c('ul',{attrs:{"id":"tabs"}},[_c('li',{staticClass:"active",attrs:{"id":"tab_1"}},[_vm._v("Description")])]),_c('div',{staticClass:"content show",attrs:{"id":"tab_1_content"}},[_c('div',{staticClass:"products-description"},[_c('p',[_vm._v("Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. Lorem Ipsum is simply dummy text of the printing.")]),_c('ul',{staticClass:"additional-information"},[_c('li',[_c('span',[_vm._v("Brand")]),_vm._v(" ThemeForest")]),_c('li',[_c('span',[_vm._v("Color")]),_vm._v(" Brown")]),_c('li',[_c('span',[_vm._v("Size")]),_vm._v(" Large, Medium")]),_c('li',[_c('span',[_vm._v("Weight")]),_vm._v(" 27 kg")]),_c('li',[_c('span',[_vm._v("Dimensions")]),_vm._v(" 16 x 22 x 123 cm")])])])])])])
}]

export { render, staticRenderFns }