<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Features</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Features Area -->
		<section class="features-area ptb-80 bg-f9f6f6">
			<div class="container">
				<div class="section-title">
					<h2>Our Features</h2>
					<div class="bar"></div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
				</div>

				<div class="row">
					<div class="col-lg-6 col-md-6 col-sm-6">
						<div class="single-features">
							<div class="icon">
								<feather type="settings"></feather>
							</div>

							<h3><a href="#">Incredible Infrastructure</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-6 col-md-6 col-sm-6">
						<div class="single-features">
							<div class="icon">
								<feather type="mail"></feather>
							</div>

							<h3><a href="#">Email Notifications</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-6 col-md-6 col-sm-6">
						<div class="single-features">
							<div class="icon bg-c679e3">
								<feather type="grid"></feather>
							</div>

							<h3><a href="#">Simple Dashboard</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-6 col-md-6 col-sm-6">
						<div class="single-features">
							<div class="icon bg-c679e3">
								<feather type="info"></feather>
							</div>

							<h3><a href="#">Information Retrieval</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-6 col-md-6 col-sm-6">
						<div class="single-features">
							<div class="icon bg-eb6b3d">
								<feather type="mouse-pointer"></feather>
							</div>

							<h3><a href="#">Drag and Drop Functionality</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>

					<div class="col-lg-6 col-md-6 col-sm-6">
						<div class="single-features">
							<div class="icon bg-eb6b3d">
								<feather type="bell"></feather>
							</div>

							<h3><a href="#">Deadline Reminders</a></h3>
							<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Features Area -->

        <!-- Start Features Area -->
		<section class="features-area-two ptb-80 bg-f7fafd">
			<div class="container">
				<div class="row h-100 justify-content-center align-items-center">
					<div class="col-lg-6 col-md-12 services-content">
						<div class="section-title">
							<h2>Experience The Best Features Ever</h2>
							<div class="bar"></div>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
						</div>

						<div class="row">
							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="lock"></feather> Secure & Fast
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="watch"></feather> Rich Experience
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="users"></feather> Support Stuffs
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="trending-up"></feather> Forex Trading
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="folder"></feather> File Backups
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="monitor"></feather> Remote Desktop
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="mail"></feather> Email Servers
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="cloud"></feather> Hybrid Cloud
								</div>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-12 services-right-image">
						<img src="../../../assets/img/services-right-image/book-self.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="book-self">
						<img src="../../../assets/img/services-right-image/box.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="box">
						<img src="../../../assets/img/services-right-image/chair.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="chair">
						<img src="../../../assets/img/services-right-image/cloud.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="cloud">
						<img src="../../../assets/img/services-right-image/cup.png" class="wow bounceIn" v-wow data-wow-delay="0.6s" alt="cup">
						<img src="../../../assets/img/services-right-image/flower-top.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="flower">
						<img src="../../../assets/img/services-right-image/head-phone.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="head-phone">
						<img src="../../../assets/img/services-right-image/monitor.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="monitor">
						<img src="../../../assets/img/services-right-image/mug.png" class="wow rotateIn" v-wow data-wow-delay="0.6s" alt="mug">
						<img src="../../../assets/img/services-right-image/table.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="table">
						<img src="../../../assets/img/services-right-image/tissue.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="tissue">
						<img src="../../../assets/img/services-right-image/water-bottle.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="water-bottle">
						<img src="../../../assets/img/services-right-image/wifi.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="wifi">
						<img src="../../../assets/img/services-right-image/cercle-shape.png" class="bg-image rotateme" alt="shape">
						
						<img src="../../../assets/img/services-right-image/main-pic.png" class="wow fadeInUp" data-wow-delay="0.6s" alt="main-pic">
					</div>
				</div>
			</div>
		</section>
		<!-- End Features Area -->

        <!-- Start Features Area -->
		<section class="features-area-two ptb-80">
			<div class="container">
				<div class="row h-100 justify-content-center align-items-center">
					<div class="col-lg-6 col-md-12 services-left-image">
						<img src="../../../assets/img/services-left-image/big-monitor.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="big-monitor">
						<img src="../../../assets/img/services-left-image/creative.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="creative">
						<img src="../../../assets/img/services-left-image/developer.png" class="wow fadeInLeft" v-wow data-wow-delay="0.6s" alt="developer">
						<img src="../../../assets/img/services-left-image/flower-top.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="flower-top">
						<img src="../../../assets/img/services-left-image/small-monitor.png" class="wow bounceIn" v-wow data-wow-delay="0.6s" alt="small-monitor">
						<img src="../../../assets/img/services-left-image/small-top.png" class="wow fadeInDown" v-wow data-wow-delay="0.6s" alt="small-top">
						<img src="../../../assets/img/services-left-image/table.png" class="wow zoomIn" v-wow data-wow-delay="0.6s" alt="table">
						<img src="../../../assets/img/services-left-image/target.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="target">
						<img src="../../../assets/img/services-left-image/cercle-shape.png" class="bg-image rotateme" alt="shape">

						<img src="../../../assets/img/services-left-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="main-pic">
					</div>

					<div class="col-lg-6 col-md-12 services-content">
						<div class="section-title">
							<h2>What Our Software Can Do For You</h2>
							<div class="bar"></div>
							<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
						</div>

						<div class="row">
							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="layout"></feather> Responsive Design
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="code"></feather> React Web Development
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="smartphone"></feather> Android Apps Development
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="code"></feather> Laravel Web Development
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="smartphone"></feather> iOS Apps Development
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="pen-tool"></feather> UX/UI Design
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="shopping-cart"></feather> E-commerce Development
								</div>
							</div>

							<div class="col-lg-6 col-md-6 col-sm-6">
								<div class="box">
									<feather type="check-circle"></feather> Print Ready Design
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Start Features Area -->
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>