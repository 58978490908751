<template>
    <!-- Start IoT Features Area -->
    <section class="iot-features-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 iot-features-content">
                    <h3>App Development for Connected Devices</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>

                    <a href="#" class="btn btn-primary">Explore More</a>
                </div>

                <div class="col-lg-6 iot-features-image">
                    <img src="../../../assets/img/iot-features-image/1.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="image">
                </div>
            </div>
        </div>
    </section>
    <!-- End IoT Features Area -->
</template>

<script>
export default {
    name: 'Features'
}
</script>