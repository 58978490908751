<template>
    <!-- Start Boxes Area -->
    <section class="boxes-area hosting-boxes-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box">
                        <div class="icon">
                            <i class="flaticon-money"></i>
                        </div>
                        <h3>Money-back Guarantee</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-f78acb">
                        <div class="icon">
                            <i class="flaticon-data-1"></i>
                        </div>
                        <h3>Big Data Transfer</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-c679e3">
                        <div class="icon">
                            <i class="flaticon-dashboard"></i>
                        </div>
                        <h3>99.9% Server Uptime</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-box bg-eb6b3d">
                        <div class="icon">
                            <i class="flaticon-support"></i>
                        </div>
                        <h3>Live 24/7 Support</h3>
                        <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Boxes Area -->
</template>

<script>
export default {
    name: 'BoxesArea'
}
</script>