<template>
    <!-- Start Why Choose US Area -->
    <section class="why-choose-us ptb-80 pt-0">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <h2>Why Choose Us</h2>
                        <div class="bar"></div>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="why-choose-us-image">
                        <img src="../../../assets/img/why-choose-us-image/man-stand.png" class="wow fadeInLeft" alt="image">
                        <img src="../../../assets/img/why-choose-us-image/database.png" class="wow fadeInRight" alt="image">
                        <img src="../../../assets/img/services-left-image/cercle-shape.png" class="rotateme" alt="image">
                        <img src="../../../assets/img/why-choose-us-image/main-static.png" class="main-pic wow fadeInUp" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <i class="flaticon-team"></i>
                                </div>
                                <h3>Proficient & Friendly</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <i class="flaticon-rocket"></i>
                                </div>
                                <h3>Extremely Fast</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <i class="flaticon-shield"></i>
                                </div>
                                <h3>100% Safe & Security</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <i class="flaticon-diamond"></i>
                                </div>
                                <h3>Top-Rated</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Why Choose US Area -->
</template>

<script>
export default {
    name: 'WhyChooseUs'
}
</script>