<template>
    <!-- Start Services Area -->
    <div class="agency-services-area ptb-80">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Our Solutions</span>
                <h2>Our Services</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="agency-services-box">
                        <img src="../../../assets/img/agency-image/services-img1.jpg" alt="image">

                        <div class="content">
                            <h3><a href="#">IT Professionals</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="agency-services-box">
                        <img src="../../../assets/img/agency-image/services-img2.jpg" alt="image">

                        <div class="content">
                            <h3><a href="#">Software Engineers</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="agency-services-box">
                        <img src="../../../assets/img/agency-image/services-img3.jpg" alt="image">

                        <div class="content">
                            <h3><a href="#">Web Development</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="agency-services-box">
                        <img src="../../../assets/img/agency-image/services-img4.jpg" alt="image">

                        <div class="content">
                            <h3><a href="#">SEO & Content</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="agency-services-box">
                        <img src="../../../assets/img/agency-image/services-img5.jpg" alt="image">

                        <div class="content">
                            <h3><a href="#">Digital Marketing</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="agency-services-box">
                        <img src="../../../assets/img/agency-image/services-img6.jpg" alt="image">

                        <div class="content">
                            <h3><a href="#">Data Analysts</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End Services Area -->
</template>

<script>
export default {
    name: 'Services'
}
</script>