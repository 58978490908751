<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>About Us</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
		<!-- End Page Title -->

        <!-- Start About Area -->
		<section class="about-area ptb-80">
			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-md-12">
						<div class="about-image">
							<img src="../../../assets/img/why-choose-us-image/image-4.png" class="wow fadeInLeft" alt="image">
						</div>
					</div>

					<div class="col-lg-6 col-md-12">
						<div class="about-content">
							<div class="section-title">
								<h2>About Us</h2>
								<div class="bar"></div>
								<p>TechnoCravers are a bunch of tech warriors who create, innovate, and deliver. Our work's epicenter has results that prove the mettle we hold. A perfect ally to our partners in digital warfare. We're based in New Delhi, India.</p>
							</div>

							<p>We offer an array of IT services like Web development, Ecommerce development, Frontend development, WordPress development, and Mobile App development, with a power-packed team of young techies craving excellence.</p>

							<p>With cutting edge technology and all web hosting platforms, we become the extension of your team to build a masterpiece of a brand.</p>

							<p>Challenging the threshold to extend the skills is one of the values TechnoCravers live by. We deliver what we promise. To give us a chance is your half work done.</p>
						</div>
					</div>
				</div>

			</div>
		</section>
		<!-- End About Area -->

       <section class="about-area ptb-80">
			<div class="container">
				<div class="row">
					<div class="col-lg-12 col-md-12">
						<div class="about-content">
							<div class="section-title">
								<h2>Our History / Who are we</h2>
								<div class="bar"></div>
							</div>

								<p>We have worked on more than 100 projects with 5 different companies, few of them being the government entities as well. The projects includes Property Tax, Water Tax, Hospital Management System, Human Resource Management System etcetra. 
								<p>Apart from managing .NET applications for Unibiz Pvt Ltd, we're working with Satoop Media, Two55am, Brainpan Studio, PMDIndia, Thor Solutions Pvt Ltd.</p> 
								<p>Our team also worked on many hybrid/native mobile applications like DGDE Directory, CGH OPD Registeration, Attendance Management System.</p>
								<p>We are working with a healthcare institute for the child and the mother, backed by the Government of Delhi, famously known as Dada Dev Matri Avum Shishu Chikitsalya. From creating a seamless platform from OPD registeration online, offline and IVR mode to automate all the laboraty reports as well.</p>
						</div>
					</div>
				</div>
			</div>
		</section>

        <!-- Start Ready To Talk Area -->
		<section class="ready-to-talk">
			<div class="container">
				<h3>Ready to talk?</h3>
				<p>Our team is here to answer your question about your queries</p>
				<a href="/contact" class="btn btn-primary">Contact Us</a>
			</div>
		</section>
		<!-- End Ready To Talk Area -->
        <Funfacts></Funfacts>
    </div>
</template>

<script>
import Funfacts from '../common/Funfacts';
export default {
    name: 'AboutStyleOne',
    components: {
        Funfacts
    }
}
</script>