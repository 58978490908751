import { render, staticRenderFns } from "./AboutStyleTwo.vue?vue&type=template&id=4ba70b1e"
import script from "./AboutStyleTwo.vue?vue&type=script&lang=js"
export * from "./AboutStyleTwo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports