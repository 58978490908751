<template>
    <!-- Start ML Main Banner -->
    <div class="ml-main-banner">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ml-banner-content">
                        <h1>World’s Leading AI & Machine Learning Company</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="btn btn-secondary">Get Started</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ml-banner-single-image wow fadeInUp" v-wow data-wow-delay="0.5s">
                        <img src="../../../assets/img/ml-banner-image/main-pic.png" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End ML Main Banner -->
</template>

<script>
export default {
    name: 'Banner'
}
</script>