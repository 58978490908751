<template>
    <!-- Start Fun Facts Area -->
    <section class="funfacts-area ptb-80">
        <div class="container">
            <div class="section-title">
                <h2>Portfolio / In a nutshell / Join Hands</h2>
                <div class="bar"></div>
                <p>We're a growing company who is steadily raising the level with every project we mother. Could you be our next client that we will take it up the notch?</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="downloaded"
                                :duration="200"
                            />
                        </span>+</h3>
                        <p>Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="feedback"
                                :duration="200"
                            />    
                        </span>+</h3>
                        <p>Technology Partners</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="workers"
                                :duration="200"
                            />    
                        </span>+</h3>
                        <p>Staff</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                    <div class="funfact">
                        <h3><span class="odometer">
                            <IOdometer
                                class="odometer"
                                :value="contributors"
                                :duration="200"
                            />
                            </span>+</h3>
                        <p>Services</p>
                    </div>
                </div>
            </div>

            <div class="contact-cta-box">
                <h3>Have any question about us?</h3>
                <p>Don't hesitate to contact us</p>
                <a href="/contact" class="btn btn-primary">Contact Us</a>
            </div>

            <div class="map-bg">
                <img src="../../../assets/img/map.png" alt="map">
            </div>
        </div>
    </section>
    <!-- End Fun Facts Area -->
</template>

<script>
import IOdometer from 'vue-odometer';
import 'odometer/themes/odometer-theme-default.css';

export default {
    name: 'Funfacts',
    data() {
        return {
            contributors: 5,
            workers: 10,
            feedback: 8,
            downloaded: 100
        }
    },
    mounted() {

    },
    components: {
        IOdometer
    }
}
</script>