<template>
    <!-- Start Footer Area -->
    <footer class="footer-area bg-footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <div class="logo">
                            <a href="/">
                                <img src="../../assets/img/logo.png" alt="logo" />
                            </a>
                        </div>
                        <p>TechnoCravers are a bunch of tech warriors who create, innovate, and deliver. Our work's epicenter has results that prove the mettle we hold. A perfect ally to our partners in digital warfare. We're based in New Delhi, India.</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Company</h3>
                        <ul class="list">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about-technocravers">About Us</router-link></li>
                            <li><router-link to="/#services">Our Services</router-link></li>
                            <li><router-link to="/#work">Our Work</router-link></li>
                            <li> <router-link to="/contact">Contact Us</router-link></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Latest Blog's</h3>
                        <ul class="list">
                            <li><a href="#">Coming Soon</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Address</h3>
                        
                        <ul class="footer-contact-info">
                            <li><feather type="map-pin"></feather>F-23A, Second Floor, Milap Nagar, Uttam Nagar,New Delhi - 110059</li>
                            <li><feather type="mail"></feather> Email: <a href="mail:contact@technocravers.com">contact@technocravers.com</a></li>
                            <li><feather type="phone-call"></feather> Phone: <a href="call:9910456981">+ (91) 9910 456 981</a></li>
                        </ul>
                        <ul class="social-links">
                            <li><a href="https://www.facebook.com/technocravers/" class="facebook"><feather type="facebook"></feather></a></li>
                            <li><a href="https://twitter.com/technocravers1" class="twitter"><feather type="twitter"></feather></a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="copyright-area">
                        <p>Copyright &copy;2020 TechnoCravers. All Rights Reserved</p>
                    </div>
                </div>
            </div>
        </div>

        <img src="../../assets/img/map.png" class="map" alt="map">
        <div class="shape1"><img src="../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../assets/img/shape2.svg" alt="shape"></div>
        <!-- <div class="go-top"><feather type="arrow-up"></feather></div> -->

        <back-to-top bottom="50px" right="50px">
            <div class="go-top"><feather type="arrow-up"></feather></div>
        </back-to-top>
    </footer>
    <!-- End Footer Area -->
</template>

<script>
import BackToTop from 'vue-backtotop'
export default {
    name: 'Footer',
    components: { BackToTop }
}
</script>