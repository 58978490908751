<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Project</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Works Area -->
		<section class="works-area ptb-80">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/1.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/2.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/3.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/4.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/5.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/1.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/2.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/3.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6">
						<div class="single-works">
							<img src="../../../assets/img/works-image/4.jpg" alt="image">

							<a href="#" class="icon"><feather type="settings"></feather></a>

							<div class="works-content">
								<h3><a href="#">Incredible infrastructure</a></h3>
								<p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
		</section>
		<!-- End Works Area -->
    </div>
</template>

<script>
export default {
    name: 'ProjectStyleOne'
}
</script>