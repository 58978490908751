var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('section',{staticClass:"features-details-area ptb-40"},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"separate"}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 features-details"},[_c('div',{staticClass:"features-details-desc"},[_c('h3',[_vm._v("Ecommerce Development")]),_c('p',[_vm._v("Took a galley of type and scrambled it to make a type specimen book. survived not only five centuries, but also the leap into electronic remaining. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer when an unknown.")]),_c('div',{staticClass:"features-details-accordion"},[_c('div',{attrs:{"role":"tablist"}},[_c('VueFaqAccordion',{attrs:{"items":_vm.myItems}})],1)])])]),_vm._m(2)]),_c('div',{staticClass:"separate"}),_vm._m(3),_c('div',{staticClass:"separate"}),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 features-details"},[_c('div',{staticClass:"features-details-desc"},[_c('h3',[_vm._v("Mobile App Development")]),_c('p',[_vm._v("Took a galley of type and scrambled it to make a type specimen book. survived not only five centuries, but also the leap into electronic remaining. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer when an unknown.")]),_c('div',{staticClass:"features-details-accordion"},[_c('div',{attrs:{"role":"tablist"}},[_c('VueFaqAccordion',{attrs:{"items":_vm.myItems}})],1)])])]),_vm._m(4)]),_c('div',{staticClass:"separate"}),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-title-area"},[_c('div',{staticClass:"d-table"},[_c('div',{staticClass:"d-table-cell"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Services")])])])]),_c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../../assets/img/shape1.png"),"alt":"shape"}})]),_c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../../../assets/img/shape3.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape5"},[_c('img',{attrs:{"src":require("../../../assets/img/shape5.png"),"alt":"shape"}})]),_c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})]),_c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 features-details-image"},[_c('img',{staticClass:"wow fadeInUp",attrs:{"src":require("../../../assets/img/features-details-image/services1.jpg"),"alt":"image"}})]),_c('div',{staticClass:"col-lg-6 features-details"},[_c('div',{staticClass:"features-details-desc"},[_c('h3',[_vm._v("Frontend Development")]),_c('p',[_vm._v("Build integration aute irure design in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat design proident.")]),_c('ul',{staticClass:"features-details-list"},[_c('li',[_vm._v("Life time supports")]),_c('li',[_vm._v("Exclusive design")]),_c('li',[_vm._v("Solve your problem with us")]),_c('li',[_vm._v("We Provide Awesome Services")]),_c('li',[_vm._v("Your business deserves best Software")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 features-details-image"},[_c('img',{staticClass:"wow fadeInUp",attrs:{"src":require("../../../assets/img/features-details-image/services2.jpg"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 features-details-image"},[_c('img',{staticClass:"wow fadeInUp",attrs:{"src":require("../../../assets/img/features-details-image/services3.jpg"),"alt":"image"}})]),_c('div',{staticClass:"col-lg-6 features-details"},[_c('div',{staticClass:"features-details-desc"},[_c('h3',[_vm._v("Web Application Development")]),_c('p',[_vm._v("Build integration aute irure design in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat design proident.")]),_c('ul',{staticClass:"features-details-list"},[_c('li',[_vm._v("Life time supports")]),_c('li',[_vm._v("Exclusive design")]),_c('li',[_vm._v("Solve your problem with us")]),_c('li',[_vm._v("We Provide Awesome Services")]),_c('li',[_vm._v("Your business deserves best Software")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 features-details-image"},[_c('img',{staticClass:"wow fadeInUp",attrs:{"src":require("../../../assets/img/features-details-image/services4.jpg"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-6 features-details-image"},[_c('img',{staticClass:"wow fadeInUp",attrs:{"src":require("../../../assets/img/features-details-image/services5.jpg"),"alt":"image"}})]),_c('div',{staticClass:"col-lg-6 features-details"},[_c('div',{staticClass:"features-details-desc"},[_c('h3',[_vm._v("Wordpress Development")]),_c('p',[_vm._v("Build integration aute irure design in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat design proident.")]),_c('ul',{staticClass:"features-details-list"},[_c('li',[_vm._v("Life time supports")]),_c('li',[_vm._v("Exclusive design")]),_c('li',[_vm._v("Solve your problem with us")]),_c('li',[_vm._v("We Provide Awesome Services")]),_c('li',[_vm._v("Your business deserves best Software")])])])])])
}]

export { render, staticRenderFns }