var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"solutions-area ptb-80"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"solutions-box"},[_vm._m(1),_vm._m(2),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])]),_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"solutions-box"},[_vm._m(3),_vm._m(4),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])]),_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"solutions-box"},[_vm._m(5),_vm._m(6),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])]),_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"solutions-box"},[_vm._m(7),_vm._m(8),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])])])]),_c('div',{directives:[{name:"wow",rawName:"v-wow"}],staticClass:"shape9 wow fadeInLeft slow"},[_c('img',{attrs:{"src":require("../../../assets/img/agency-image/shape1.png"),"alt":"image"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('span',{staticClass:"sub-title"},[_vm._v("What We Offer")]),_c('h2',[_vm._v("Our Featured Solutions")]),_c('div',{staticClass:"bar"}),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"bx bx-landscape"}),_c('img',{attrs:{"src":require("../../../assets/img/agency-image/icon-bg.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Programmatic Advertising")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"bx bx-bar-chart-alt"}),_c('img',{attrs:{"src":require("../../../assets/img/agency-image/icon-bg.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Strategy & Research")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"bx bx-devices"}),_c('img',{attrs:{"src":require("../../../assets/img/agency-image/icon-bg.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Design & Development")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"bx bx-chalkboard"}),_c('img',{attrs:{"src":require("../../../assets/img/agency-image/icon-bg.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Branding & Marketing")])])
}]

export { render, staticRenderFns }