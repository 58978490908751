<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Services</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Our Solutions Area -->
		<section class="solutions-area ptb-80">
			<div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-landscape"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Programmatic Advertising</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-bar-chart-alt"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Strategy & Research</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-devices"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Design & Development</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-chalkboard"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Branding & Marketing</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-landscape"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Programmatic Advertising</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-bar-chart-alt"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Strategy & Research</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-devices"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Design & Development</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="solutions-box">
                            <div class="icon">
                                <i class="bx bx-chalkboard"></i>
                                <img src="../../../assets/img/agency-image/icon-bg.png" alt="image">
                            </div>

                            <h3><a href="#">Branding & Marketing</a></h3>
                            <p>Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                            <a href="#" class="learn-more-btn"><feather type="plus-circle"></feather> Learn More</a>
                        </div>
                    </div>
                </div>
			</div>

			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
		</section>
        <!-- End Our Solutions Area -->
    </div>
</template>

<script>
export default {
    name: 'ServicesFour'
}
</script>