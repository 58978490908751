<template>
    <!-- Start Features Area -->
    <section class="hosting-features-area ptb-80 bg-f9f6f6">
        <div class="container">
            <div class="section-title">
                <h2>Our Features</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-hosting-features">
                        <div class="icon">
                            <feather type="settings"></feather>
                        </div>

                        <h3>Incredible Infrastructure</h3>
                        <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-hosting-features">
                        <div class="icon bg-c679e3">
                            <feather type="info"></feather>
                        </div>

                        <h3>Information Retrieval</h3>
                        <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-hosting-features">
                        <div class="icon">
                            <feather type="bell"></feather>
                        </div>

                        <h3>Best Analytics Audits</h3>
                        <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-hosting-features">
                        <div class="icon bg-c679e3">
                            <feather type="grid"></feather>
                        </div>

                        <h3>Simple Dashboard</h3>
                        <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-hosting-features">
                        <div class="icon">
                            <feather type="mail"></feather>
                        </div>

                        <h3>Email Notifications</h3>
                        <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-hosting-features">
                        <div class="icon bg-c679e3">
                            <feather type="hard-drive"></feather>
                        </div>

                        <h3>Deep Technical SEO</h3>
                        <p>Lorem ipsum dolor amet, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Features Area -->
</template>

<script>
export default {
    name: 'HostingFeatures'
}
</script>