<template>
    <!-- Start IoT Features Area -->
    <section class="iot-features-area ptb-80">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 iot-features-image">
                    <img src="../../../assets/img/iot-features-image/2.png" class="wow fadeInUp" v-wow data-wow-delay="0.6s" alt="image">
                </div>
                
                <div class="col-lg-6 iot-features-content">
                    <h3>How Can Your City Use IoT Technology?</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus.</p>

                    <a href="#" class="btn btn-primary">Explore More</a>
                </div>
            </div>
        </div>
    </section>
    <!-- End IoT Features Area -->
</template>

<script>
export default {
    name: 'FeaturesTwo'
}
</script>