<template>
    <!-- Start ML Main Banner -->
    <div class="ml-main-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="ml-banner-content">
                        <h1>World’s Leading AI & Machine Learning Company</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                        <a href="#" class="btn btn-secondary">Get Started</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ml-banner-image">
                        <img src="../../../assets/img/ml-banner-image/1.png" class="wow fadeIn" v-wow data-wow-delay="2s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/2.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/3.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/4.png" class="wow fadeInUp" v-wow data-wow-delay="2s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/5.png" class="wow fadeInDown" v-wow data-wow-delay="2.5s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/6.png" class="wow fadeInDown" v-wow data-wow-delay="1.9s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/7.png" class="wow fadeInDown" v-wow data-wow-delay="2.1s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/8.png" class="wow fadeInDown" v-wow data-wow-delay="1.9s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/9.png" class="wow fadeInDown" v-wow data-wow-delay="1.7s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/10.png" class="wow fadeInDown" v-wow data-wow-delay="1.5s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/11.png" class="wow fadeInUp" v-wow data-wow-delay="1s" alt="image">
                        <img src="../../../assets/img/ml-banner-image/main-pic.png" class="wow fadeInUp" v-wow data-wow-delay="0.5s" alt="image">
                    </div>
                </div>
            </div>
        </div>

        <div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </div>
    <!-- End ML Main Banner -->
</template>

<script>
export default {
    name: 'Banner'
}
</script>