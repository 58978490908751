<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Project</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start ML Projects Area -->
        <section class="ml-projects-area ptb-80">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img1.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img2.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img3.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img4.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img5.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img6.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img7.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/projects-img8.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- End ML Projects Area -->
    </div>
</template>

<script>
export default {
    name: 'ProjectStyleTwo'
}
</script>