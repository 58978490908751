var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"blog-area ptb-80"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-blog-post"},[_vm._m(1),_c('div',{staticClass:"blog-post-content"},[_vm._m(2),_vm._m(3),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.")]),_c('a',{staticClass:"read-more-btn",attrs:{"href":"#"}},[_vm._v("Read More "),_c('feather',{attrs:{"type":"arrow-right"}})],1)])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-blog-post"},[_vm._m(4),_c('div',{staticClass:"blog-post-content"},[_vm._m(5),_vm._m(6),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.")]),_c('a',{staticClass:"read-more-btn",attrs:{"href":"#"}},[_vm._v("Read More "),_c('feather',{attrs:{"type":"arrow-right"}})],1)])])]),_c('div',{staticClass:"col-lg-4 col-md-6 offset-lg-0 offset-md-3"},[_c('div',{staticClass:"single-blog-post"},[_vm._m(7),_c('div',{staticClass:"blog-post-content"},[_vm._m(8),_vm._m(9),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.")]),_c('a',{staticClass:"read-more-btn",attrs:{"href":"#"}},[_vm._v("Read More "),_c('feather',{attrs:{"type":"arrow-right"}})],1)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("The News from Our Blog")]),_c('div',{staticClass:"bar"}),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-image"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../../assets/img/blog-image/1.jpg"),"alt":"image"}})]),_c('div',{staticClass:"date"},[_c('i',{attrs:{"data-feather":"calendar"}}),_vm._v(" March 15, 2019 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("The security risks of changing package owners")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("by "),_c('a',{attrs:{"href":"#"}},[_vm._v("admin")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-image"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../../assets/img/blog-image/2.jpg"),"alt":"image"}})]),_c('div',{staticClass:"date"},[_c('i',{attrs:{"data-feather":"calendar"}}),_vm._v(" March 17, 2019 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Tips to Protecting Your Business and Family")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("by "),_c('a',{attrs:{"href":"#"}},[_vm._v("smith")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"blog-image"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../../assets/img/blog-image/3.jpg"),"alt":"image"}})]),_c('div',{staticClass:"date"},[_c('i',{attrs:{"data-feather":"calendar"}}),_vm._v(" March 19, 2019 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Protect Your Workplace from Cyber Attacks")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("by "),_c('a',{attrs:{"href":"#"}},[_vm._v("john")])])
}]

export { render, staticRenderFns }