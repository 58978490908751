var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"ml-projects-area pt-0 ptb-80"},[_vm._m(0),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"ml-projects-slides"},[_c('carousel',{attrs:{"autoplay":true,"paginationEnabled":false,"loop":true,"smartSpeed":1000,"autoplayTimeout":5000,"perPageCustom":[[0, 1],[576, 2],[768, 2], [1024, 3], [1200, 4]]}},[_c('slide',[_c('div',{staticClass:"single-ml-projects-box"},[_c('img',{attrs:{"src":require("../../../assets/img/projects-img1.jpg"),"alt":"image"}}),_c('div',{staticClass:"plus-icon"},[_c('a',{attrs:{"href":"#"}},[_c('span')])])])]),_c('slide',[_c('div',{staticClass:"single-ml-projects-box"},[_c('img',{attrs:{"src":require("../../../assets/img/projects-img2.jpg"),"alt":"image"}}),_c('div',{staticClass:"plus-icon"},[_c('a',{attrs:{"href":"#"}},[_c('span')])])])]),_c('slide',[_c('div',{staticClass:"single-ml-projects-box"},[_c('img',{attrs:{"src":require("../../../assets/img/projects-img3.jpg"),"alt":"image"}}),_c('div',{staticClass:"plus-icon"},[_c('a',{attrs:{"href":"#"}},[_c('span')])])])]),_c('slide',[_c('div',{staticClass:"single-ml-projects-box"},[_c('img',{attrs:{"src":require("../../../assets/img/projects-img4.jpg"),"alt":"image"}}),_c('div',{staticClass:"plus-icon"},[_c('a',{attrs:{"href":"#"}},[_c('span')])])])]),_c('slide',[_c('div',{staticClass:"single-ml-projects-box"},[_c('img',{attrs:{"src":require("../../../assets/img/projects-img5.jpg"),"alt":"image"}}),_c('div',{staticClass:"plus-icon"},[_c('a',{attrs:{"href":"#"}},[_c('span')])])])])],1)],1)]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Proud Projects That Make Us Stand Out")]),_c('div',{staticClass:"bar"}),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../../../assets/img/shape3.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }