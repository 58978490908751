<template>
    <!-- Start Pricing Area -->
    <section class="pricing-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Pricing Plans</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-table">
                        <div class="pricing-header">
                            <i class="flaticon-data"></i>
                            <h3>Web Hosting</h3>
                        </div>
                        
                        <div class="price">
                            <span><sup>$</sup>10.99<span>/m</span></span>
                        </div>
                        
                        <div class="pricing-features">
                            <ul>
                                <li><feather type="check"></feather> 99.9% Uptime Guarantee</li>
                                <li><feather type="check"></feather> Reliable & Secure</li>
                                <li><feather type="check"></feather> Powered by cPanel / Plesk</li>
                                <li><feather type="check"></feather> Web Asset Delivery</li>
                                <li><feather type="check"></feather> 24/7 Dedicated Support</li>
                            </ul>
                        </div>
                        
                        <div class="pricing-footer">
                            <a href="#" class="btn btn-primary">Select Plan</a>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-table active-plan">
                        <div class="pricing-header">
                            <i class="flaticon-cloud"></i>
                            <h3>Cloud Hosting</h3>
                        </div>
                        
                        <div class="price">
                            <span><sup>$</sup>13.99<span>/m</span></span>
                        </div>
                        
                        <div class="pricing-features">
                            <ul>
                                <li><feather type="check"></feather> 99.9% Uptime Guarantee</li>
                                <li><feather type="check"></feather> Reliable & Secure</li>
                                <li><feather type="check"></feather> Powered by cPanel / Plesk</li>
                                <li><feather type="check"></feather> Web Asset Delivery</li>
                                <li><feather type="check"></feather> 24/7 Dedicated Support</li>
                            </ul>
                        </div>
                        
                        <div class="pricing-footer">
                            <a href="#" class="btn btn-primary">Select Plan</a>
                        </div>
                    </div>
                </div>
                
                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-pricing-table">
                        <div class="pricing-header">
                            <i class="flaticon-vps"></i>
                            <h3>Vps Hosting</h3>
                        </div>
                        
                        <div class="price">
                            <span><sup>$</sup>15.99<span>/m</span></span>
                        </div>
                        
                        <div class="pricing-features">
                            <ul>
                                <li><feather type="check"></feather> 99.9% Uptime Guarantee</li>
                                <li><feather type="check"></feather> Reliable & Secure</li>
                                <li><feather type="check"></feather> Powered by cPanel / Plesk</li>
                                <li><feather type="check"></feather> Web Asset Delivery</li>
                                <li><feather type="check"></feather> 24/7 Dedicated Support</li>
                            </ul>
                        </div>
                        
                        <div class="pricing-footer">
                            <a href="#" class="btn btn-primary">Select Plan</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
    </section>
    <!-- End Pricing Area -->
</template>

<script>
export default {
    name: 'Pricing'
}
</script>