var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"blog-area ptb-80"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-blog-post-box"},[_vm._m(1),_c('div',{staticClass:"entry-post-content"},[_vm._m(2),_vm._m(3),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_vm._v("Read Story "),_c('feather',{attrs:{"type":"plus"}})],1)])])]),_c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"single-blog-post-box"},[_vm._m(4),_c('div',{staticClass:"entry-post-content"},[_vm._m(5),_vm._m(6),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_vm._v("Read Story "),_c('feather',{attrs:{"type":"plus"}})],1)])])]),_c('div',{staticClass:"col-lg-4 col-md-6 offset-md-3 offset-lg-0"},[_c('div',{staticClass:"single-blog-post-box"},[_vm._m(7),_c('div',{staticClass:"entry-post-content"},[_vm._m(8),_vm._m(9),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_vm._v("Read Story "),_c('feather',{attrs:{"type":"plus"}})],1)])])])])]),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Our Recent Story")]),_c('div',{staticClass:"bar"}),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-thumbnail"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../../assets/img/blog-image/9.jpg"),"alt":"image"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Admin")])]),_c('li',[_vm._v("August 15, 2019")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Making Peace With The Feast Or Famine Of Freelancing")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-thumbnail"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../../assets/img/blog-image/10.jpg"),"alt":"image"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Admin")])]),_c('li',[_vm._v("August 18, 2019")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("I Used The Web For A Day On A 50 MB Budget")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-thumbnail"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../../assets/img/blog-image/11.jpg"),"alt":"image"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"entry-meta"},[_c('ul',[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Admin")])]),_c('li',[_vm._v("August 15, 2019")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Here are the 5 most telling signs of micromanagement")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../../../assets/img/shape3.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }