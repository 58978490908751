<template>
    <!-- Start Why Choose US Area -->
    <section class="why-choose-us ptb-80"  id="wordpress">
        <div class="container" >
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <h2>Wordpress Development</h2>
                        <div class="bar"></div>
                        <p>WordPress administers one-third of the world's websites. TechnoCravers are a bunch having exorbitant expertise in WordPress CMS (Content Management System), themes, and plugins. All we ask is for you to give us your requirement, or if you're facing an issue on your website, WordPress has all the solutions. Providing a bug-free experience is our forte!</p>
					</div>
					
                    <div class="why-choose-us-image">
                        <img src="../../../assets/img/why-choose-us-image/man-img1.png" class="wow fadeInLeft" alt="image">
                        <img src="../../../assets/img/services-left-image/cercle-shape.png" class="rotateme" alt="image">
                        <img src="../../../assets/img/why-choose-us-image/man-img1.png" class="main-pic wow fadeInUp" alt="image">
					</div>
				</div>
				
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/ionic-icon.png" alt="image">
								</div>
                                <h3>PSD To Theme</h3>
                                <p>Are you looking to Convert a PSD to WordPress theme? Look no more! Hit the 'Contact us' tab now, for a responsive WordPress theme with a fully dynamic content.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/ios-icon.png" alt="image">
								</div>
                                <h3>Fast & Secure</h3>
                                <p>WordPress have many plugins to make your site Fast & Secure, Best configuration is what one needs. Hire us to configure those plugins with the best settings.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/android-icon.png" alt="image">
								</div>
                                <h3>Installation & Configuration</h3>
                                <p>We will just not provide the WordPress installation to our clients, but also the best of it, to configure it perfectly. Hire us and see the difference!</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/react-icon.png" alt="image">
								</div>
                                <h3>Custom Module Management</h3>
                                <p>If you are looking for any custom module plugin or looking for any changes in the existing plugin, we are here for you.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
        </section>
    <!-- End Why Choose US Area -->
</template>

<script>
	export default {
		name: 'Wordpress'
	}
</script>