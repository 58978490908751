var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-main-banner"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(0),_c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{directives:[{name:"wow",rawName:"v-wow"}],staticClass:"ml-banner-single-image wow fadeInUp",attrs:{"data-wow-delay":"0.5s"}},[_c('img',{attrs:{"src":require("../../../assets/img/ml-banner-image/main-pic.png"),"alt":"image"}})])])])]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 col-md-12"},[_c('div',{staticClass:"ml-banner-content"},[_c('h1',[_vm._v("World’s Leading AI & Machine Learning Company")]),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.")]),_c('a',{staticClass:"btn btn-secondary",attrs:{"href":"#"}},[_vm._v("Get Started")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../../../assets/img/shape3.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }