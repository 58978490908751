<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Blog Right Sidebar</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Blog Area -->
		<section class="blog-area ptb-80">
			<div class="container">
				<div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-box">
                                    <div class="entry-thumbnail">
                                        <a href="#"><img src="../../../assets/img/blog-image/9.jpg" alt="image"></a>
                                    </div>
        
                                    <div class="entry-post-content">
                                        <div class="entry-meta">
                                            <ul>
                                                <li><a href="#">Admin</a></li>
                                                <li>August 15, 2019</li>
                                            </ul>
                                        </div>
        
                                        <h3><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                        <a href="#" class="learn-more-btn">Read Story <feather type="plus"></feather></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-box">
                                    <div class="entry-thumbnail">
                                        <a href="#"><img src="../../../assets/img/blog-image/10.jpg" alt="image"></a>
                                    </div>
        
                                    <div class="entry-post-content">
                                        <div class="entry-meta">
                                            <ul>
                                                <li><a href="#">Admin</a></li>
                                                <li>August 18, 2019</li>
                                            </ul>
                                        </div>
        
                                        <h3><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                        <a href="#" class="learn-more-btn">Read Story <feather type="plus"></feather></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-box">
                                    <div class="entry-thumbnail">
                                        <a href="#"><img src="../../../assets/img/blog-image/11.jpg" alt="image"></a>
                                    </div>
        
                                    <div class="entry-post-content">
                                        <div class="entry-meta">
                                            <ul>
                                                <li><a href="#">Admin</a></li>
                                                <li>August 15, 2019</li>
                                            </ul>
                                        </div>
        
                                        <h3><a href="#">Here are the 5 most telling signs of micromanagement</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                        <a href="#" class="learn-more-btn">Read Story <feather type="plus"></feather></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-box">
                                    <div class="entry-thumbnail">
                                        <a href="#"><img src="../../../assets/img/blog-image/12.jpg" alt="image"></a>
                                    </div>
        
                                    <div class="entry-post-content">
                                        <div class="entry-meta">
                                            <ul>
                                                <li><a href="#">Admin</a></li>
                                                <li>August 15, 2019</li>
                                            </ul>
                                        </div>
        
                                        <h3><a href="#">The security risks of changing package owners</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                        <a href="#" class="learn-more-btn">Read Story <feather type="plus"></feather></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-box">
                                    <div class="entry-thumbnail">
                                        <a href="#"><img src="../../../assets/img/blog-image/13.jpg" alt="image"></a>
                                    </div>
        
                                    <div class="entry-post-content">
                                        <div class="entry-meta">
                                            <ul>
                                                <li><a href="#">Admin</a></li>
                                                <li>August 18, 2019</li>
                                            </ul>
                                        </div>
        
                                        <h3><a href="#">Tips to Protecting Your Business and Family</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                        <a href="#" class="learn-more-btn">Read Story <feather type="plus"></feather></a>
                                    </div>
                                </div>
                            </div>
        
                            <div class="col-lg-6 col-md-6">
                                <div class="single-blog-post-box">
                                    <div class="entry-thumbnail">
                                        <a href="#"><img src="../../../assets/img/blog-image/14.jpg" alt="image"></a>
                                    </div>
        
                                    <div class="entry-post-content">
                                        <div class="entry-meta">
                                            <ul>
                                                <li><a href="#">Admin</a></li>
                                                <li>August 15, 2019</li>
                                            </ul>
                                        </div>
        
                                        <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod...</p>
                                        <a href="#" class="learn-more-btn">Read Story <feather type="plus"></feather></a>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-lg-12 col-md-12">
                                <div class="pagination-area">
                                    <nav aria-label="Page navigation">
                                        <ul class="pagination justify-content-center">
                                           
                                            <li class="page-item"><a class="page-link" href="#">Prev</a></li>
                                            
                                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                            
                                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                                            
                                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                                            
                                            <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget_search">
                                <form class="search-form">
                                    <label>
                                        <span class="screen-reader-text">Search for:</span>
                                        <input type="search" class="search-field" placeholder="Search...">
                                    </label>
                                    <button type="submit"><feather type="search"></feather></button>
                                </form>
                            </section>

                            <section class="widget widget_startp_posts_thumb">
                                <h3 class="widget-title">Popular Posts</h3>

                                <article class="item">
                                    <a href="#" class="thumb">
                                        <span class="fullimage cover bg1" role="img"></span>
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">June 10, 2019</time>
                                        <h4 class="title usmall"><a href="#">Making Peace With The Feast Or Famine Of Freelancing</a></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>

                                <article class="item">
                                    <a href="#" class="thumb">
                                        <span class="fullimage cover bg2" role="img"></span>
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">June 21, 2019</time>
                                        <h4 class="title usmall"><a href="#">I Used The Web For A Day On A 50 MB Budget</a></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>

                                <article class="item">
                                    <a href="#" class="thumb">
                                        <span class="fullimage cover bg3" role="img"></span>
                                    </a>
                                    <div class="info">
                                        <time datetime="2019-06-30">June 30, 2019</time>
                                        <h4 class="title usmall"><a href="#">How To Create A Responsive Popup Gallery?</a></h4>
                                    </div>

                                    <div class="clear"></div>
                                </article>
                            </section>

                            <section class="widget widget_categories">
                                <h3 class="widget-title">Categories</h3>

                                <ul>
                                    <li><a href="#">Business</a></li>
                                    <li><a href="#">Privacy</a></li>
                                    <li><a href="#">Technology</a></li>
                                    <li><a href="#">Tips</a></li>
                                    <li><a href="#">Uncategorized</a></li>
                                </ul>
                            </section>

                            <section class="widget widget_archive">
                                <h3 class="widget-title">Archives</h3>

                                <ul>
                                    <li><a href="#">May 2019</a></li>
                                    <li><a href="#">April 2019</a></li>
                                    <li><a href="#">June 2019</a></li>
                                </ul>
                            </section>

                            <section class="widget widget_meta">
                                <h3 class="widget-title">Meta</h3>

                                <ul>
                                    <li><a href="#">Log in</a></li>
                                    <li><a href="#">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="#">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                                    <li><a href="#">WordPress.org</a></li>
                                </ul>
                            </section>

                            <section class="widget widget_tag_cloud">
                                <h3 class="widget-title">Tags</h3>

                                <div class="tagcloud">
                                    <a href="#">IT <span class="tag-link-count"> (3)</span></a>
                                    <a href="#">Spacle <span class="tag-link-count"> (3)</span></a>
                                    <a href="#">Games <span class="tag-link-count"> (2)</span></a>
                                    <a href="#">Fashion <span class="tag-link-count"> (2)</span></a>
                                    <a href="#">Travel <span class="tag-link-count"> (1)</span></a>
                                    <a href="#">Smart <span class="tag-link-count"> (1)</span></a>
                                    <a href="#">Marketing <span class="tag-link-count"> (1)</span></a>
                                    <a href="#">Tips <span class="tag-link-count"> (2)</span></a>
                                </div>
                            </section>
                        </aside>
                    </div>
                </div>
			</div>
		</section>
		<!-- End Blog Area -->
    </div>
</template>

<script>
export default {
    name: 'BlogRightSidebarTwo'
}
</script>