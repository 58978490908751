<template>
    <!-- Start Why Choose US Area -->
   <section class="why-choose-us ptb-80"  id="ecommerce">
        <div class="container ptb-80" >
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="section-title">
                        <h2>Ecommerce Development</h2>
                        <div class="bar"></div>
                        <p>Give your audience a smart, enchanting platform for a shopping spree. We, TechnoCravers, brainstorm with our clients as a pre-requisite to developing an e-commerce platform after a sharp analysis of market trends and the target audience, with a perfect blend of innovation and technology.</p>
					</div>
					
                    <div class="why-choose-us-image">
						<img src="../../../assets/img/why-choose-us-image/image-2.png" class="wow fadeInLeft" alt="image">
                        <img src="../../../assets/img/services-left-image/cercle-shape.png" class="rotateme" alt="image">
                        <img src="../../../assets/img/why-choose-us-image/image-2.png" class="main-pic wow fadeInUp" alt="image">
					</div>
				</div>
				
                <div class="col-lg-6 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
                                    <img src="../../../assets/img/why-choose-us-image/opencart-icon.png" alt="image">
								</div>
                                <h3>Opencart</h3>
                                <p>OpenCart is an PHP-based online e-commerce solution. Our team has worked on more than 5 OpenCart Projects.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/shopify-icon.png" alt="image">
								</div>
                                <h3>Shopify</h3>
                                <p>Shopify is an e-commerce platform that helps you to sell online. We help you to customize the software as per your needs.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/woocommerce-icon.png" alt="image">
								</div>
                                <h3>Woocommerce</h3>
                                <p>WooCommerce is an open-source e-commerce plugin for WordPress. We help you to customize it as per your business needs.</p>
							</div>
						</div>
						
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="single-why-choose-us">
                                <div class="icon">
									<img src="../../../assets/img/why-choose-us-image/magento-icon.png" alt="image">
								</div>
                                <h3>Magento</h3>
                                <p>Magento empowers thousands of retailers and brands with the best e-commerce platforms. Hire us to empower your e-commerce business.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
    
    <!-- End Why Choose US Area -->
</template>

<script>
	export default {
		name: 'Ecommerce'
	}
</script>