<template>
    <!-- Start ML Projects Area -->
    <section class="ml-projects-area ptb-80" id="work">
        <div class="container">
            <div class="section-title">
                <h2>Proud Projects That Make Us Stand Out</h2>
                <div class="bar"></div>
                <p>"The world accomodates you for fitting in, but only rewards you for standing out."</p>
<p>We are proud to showcase the projects below, which has made us shine brighter. In a world full of daisies, we dare to be a rose. TechnoCravers for a reason!</p>

            </div>
        </div>

        <div class="container-fluid">
            <div class="ml-projects-slides">
                <carousel 
                    :autoplay = "true" 
                    :paginationEnabled = "false"
                    :loop = "true"
                    :smartSpeed = "1000"
                    :autoplayTimeout = "5000"
                    :perPageCustom = "[[0, 1],[576, 2],[768, 2], [1024, 3], [1200, 4]]"
                >
                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/rene.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>


                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/smuze.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/metaanoi.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/curol.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/schef-works.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/artography.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/pocket-law.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                     <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/taraspan.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                     <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/water-experts.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                    <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/four-s.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                 
                 
                     <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/rocketeers.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                     <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/pocket-law-app.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                     <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/solar-app.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                     <slide>
                        <div class="single-ml-projects-box">
                            <img src="../../../assets/img/lancer.jpg" alt="image">
                        
                            <div class="plus-icon">
                                <a href="#">
                                    <span></span>
                                </a>
                            </div>
                        </div>
                    </slide>
                    
                    
                 
                </carousel>
            </div>
        </div>

        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </section>
    <!-- End ML Projects Area -->
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
    name: 'Projects',
    components: { Carousel, Slide }
}
</script>