<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Team</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Team Area -->
		<section class="team-area ptb-80 bg-f9f6f6">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/1.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Josh Buttler</h3>
									<span>CEO & Founder</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/2.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Alex Maxwel</h3>
									<span>Marketing Manager</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/3.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Janny Cotller</h3>
									<span>Web Developer</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/4.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Jason Statham</h3>
									<span>UX/UI Designer</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/5.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Corey Anderson</h3>
									<span>Project Manager</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/1.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Josh Buttler</h3>
									<span>CEO & Founder</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/2.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Alex Maxwel</h3>
									<span>Marketing Manager</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/3.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Janny Cotller</h3>
									<span>Web Developer</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>

					<div class="col-lg-4 col-md-6 col-sm-6">
						<div class="single-team">
							<div class="team-image">
								<img src="../../../assets/img/team-image/4.jpg" alt="image">
							</div>

							<div class="team-content">
								<div class="team-info">
									<h3>Jason Statham</h3>
									<span>UX/UI Designer</span>
								</div>

								<ul>
									<li><a href="#"><feather type="facebook"></feather></a></li>
									<li><a href="#"><feather type="twitter"></feather></a></li>
									<li><a href="#"><feather type="linkedin"></feather></a></li>
									<li><a href="#"><feather type="gitlab"></feather></a></li>
								</ul>

								<p>Risus commodo viverra maecenas accumsan lacus vel facilisis quis ipsum. </p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- End Team Area -->
    </div>
</template>

<script>
export default {
    name: 'Team'
}
</script>