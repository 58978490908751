<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Pricing</h2>
                    </div>
                </div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        </div>
        <!-- End Page Title -->

        <!-- Start Pricing Area -->
		<section class="pricing-area ptb-80 bg-f9f6f6">
			<div class="container">
				<div class="section-title">
					<h2>Pricing Plans</h2>
					<div class="bar"></div>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
				</div>

				<div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                <h3>Basic Plan</h3>
                            </div>
                            
                            <div class="price">
                                <span><sup>$</sup>15.00 <span>/Mon</span></span>
                            </div>
                            
                            <div class="pricing-features">
                                <ul>
                                    <li class="active">5 GB Bandwidth</li>
                                    <li class="active">Highest Speed</li>
                                    <li class="active">1 GB Storage</li>
                                    <li class="active">Unlimited Website</li>
                                    <li class="active">Unlimited Users</li>
                                    <li class="active">24x7 Great Support</li>
                                    <li>Data Security and Backups</li>
                                    <li>Monthly Reports and Analytics</li>
                                </ul>
                            </div>
                            
                            <div class="pricing-footer">
                                <a href="#" class="btn btn-primary">Select Plan</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="pricing-table active-plan">
                            <div class="pricing-header">
                                <h3>Advanced Plan</h3>
                            </div>
                            
                            <div class="price">
                                <span><sup>$</sup>35.00 <span>/Mon</span></span>
                            </div>
                            
                            <div class="pricing-features">
                                <ul>
                                    <li class="active">10 GB Bandwidth</li>
                                    <li class="active">Highest Speed</li>
                                    <li class="active">3 GB Storage</li>
                                    <li class="active">Unlimited Website</li>
                                    <li class="active">Unlimited Users</li>
                                    <li class="active">24x7 Great Support</li>
                                    <li class="active">Data Security and Backups</li>
                                    <li>Monthly Reports and Analytics</li>
                                </ul>
                            </div>
                            
                            <div class="pricing-footer">
                                <a href="#" class="btn btn-primary">Select Plan</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="pricing-table">
                            <div class="pricing-header">
                                <h3>Expert Plan</h3>
                            </div>
                            
                            <div class="price">
                                <span><sup>$</sup>65.00 <span>/Mon</span></span>
                            </div>
                            
                            <div class="pricing-features">
                                <ul>
                                    <li class="active">15 GB Bandwidth</li>
                                    <li class="active">Highest Speed</li>
                                    <li class="active">5 GB Storage</li>
                                    <li class="active">Unlimited Website</li>
                                    <li class="active">Unlimited Users</li>
                                    <li class="active">24x7 Great Support</li>
                                    <li class="active">Data Security and Backups</li>
                                    <li class="active">Monthly Reports and Analytics</li>
                                </ul>
                            </div>
                            
                            <div class="pricing-footer">
                                <a href="#" class="btn btn-primary">Select Plan</a>
                            </div>
                        </div>
                    </div>
				</div>
			</div>

			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
		</section>
		<!-- End Pricing Area -->
    </div>
</template>

<script>
export default {
    name: 'Pricing'
}
</script>