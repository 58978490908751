var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"solutions-area ptb-80"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"single-solutions-box"},[_vm._m(1),_vm._m(2),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])]),_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"single-solutions-box"},[_vm._m(3),_vm._m(4),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])]),_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"single-solutions-box"},[_vm._m(5),_vm._m(6),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])]),_c('div',{staticClass:"col-lg-3 col-sm-6 col-md-6"},[_c('div',{staticClass:"single-solutions-box"},[_vm._m(7),_vm._m(8),_c('p',[_vm._v("Lorem ipsum dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.")]),_c('a',{staticClass:"learn-more-btn",attrs:{"href":"#"}},[_c('feather',{attrs:{"type":"plus-circle"}}),_vm._v(" Learn More")],1)])])])]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section-title"},[_c('span',{staticClass:"sub-title"},[_vm._v("What We Offer")]),_c('h2',[_vm._v("Our Featured Solutions")]),_c('div',{staticClass:"bar"}),_c('p',[_vm._v("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/img/icon4.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Robotic Automation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/img/icon5.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Cognitive Automation")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/img/icon6.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Cognitive Engagement")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("../../../assets/img/icon7.png"),"alt":"image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('a',{attrs:{"href":"#"}},[_vm._v("Security & Surveillance")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape1"},[_c('img',{attrs:{"src":require("../../../assets/img/shape1.png"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape2 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape3"},[_c('img',{attrs:{"src":require("../../../assets/img/shape3.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape4"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape6 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape7"},[_c('img',{attrs:{"src":require("../../../assets/img/shape4.svg"),"alt":"shape"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shape8 rotateme"},[_c('img',{attrs:{"src":require("../../../assets/img/shape2.svg"),"alt":"shape"}})])
}]

export { render, staticRenderFns }