<template>
    <!-- Start Repair Services Area -->
    <section class="repair-services-area ptb-80 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>Our Featured Services that We Provide</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-services bg1">
                        <div class="icon">
                            <i class="flaticon-monitor"></i>
                        </div>

                        <h3>Laptop Repair</h3>
                        <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        
                        <a href="#"><i data-feather="arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-services bg2">
                        <div class="icon">
                            <i class="flaticon-idea"></i>
                        </div>

                        <h3>Computer Repair</h3>
                        <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        
                        <a href="#"><i data-feather="arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-services bg3">
                        <div class="icon">
                            <i class="flaticon-layout"></i>
                        </div>

                        <h3>Apple Products Repair</h3>
                        <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        
                        <a href="#"><i data-feather="arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-services bg4">
                        <div class="icon">
                            <i class="flaticon-update-arrows"></i>
                        </div>

                        <h3>Software Update</h3>
                        <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        
                        <a href="#"><i data-feather="arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-services bg5">
                        <div class="icon">
                            <i class="flaticon-smartphone"></i>
                        </div>

                        <h3>Smartphone Repair</h3>
                        <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        
                        <a href="#"><i data-feather="arrow-right"></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-repair-services bg6">
                        <div class="icon">
                            <i class="flaticon-hard-disk"></i>
                        </div>

                        <h3>Data Backup & Recovery</h3>
                        <p>Lorem ipsum eiusmod dolor sit amet elit, adipiscing, sed do eiusmod tempor incididunt ut labore dolore magna aliqua.</p>
                        
                        <a href="#"><i data-feather="arrow-right"></i></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- End Repair Services Area -->
</template>

<script>
export default {
    name: 'Services'
}
</script>