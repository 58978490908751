<template>
    <div>
        <Banner></Banner>
        <Services></Services>
        <WhyChooseUs></WhyChooseUs>
        <Ecommerce></Ecommerce>
        <Frontend></Frontend>
        <Mobile></Mobile>
        <WebApplication></WebApplication>
        <Wordpress></Wordpress>
        <Projects></Projects>
        <Features></Features>
      
        <!-- Start Ready To Talk Area -->
		
		<!-- End Ready To Talk Area -->
       
    </div>
</template>

<script>
import Banner from './it-startup/Banner';
import Services from './it-startup/Services';
import WhyChooseUs from './it-startup/WhyChooseUs';
import Ecommerce from './it-startup/Ecommerce';
import Frontend from './it-startup/Frontend';
import Mobile from './it-startup/Mobile';
import WebApplication from './it-startup/WebApplication';
import Wordpress from './it-startup/Wordpress';
import Projects from './it-startup/Projects';
export default {
    name: 'ITStartup',
    components: {
        Banner,
        Services,
        WhyChooseUs,
        Ecommerce,
        Frontend,
        Mobile,
        WebApplication,
        Wordpress,
        Projects
    }
}
</script>