<template>
    <!-- Start Blog Area -->
    <section class="blog-area ptb-80">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">News</span>
                <h2>The News from Our Blog</h2>
                <div class="bar"></div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post-item">
                        <div class="post-image">
                            <a href="#">
                                <img src="../../../assets/img/blog-image/1.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta">
                                <li><a href="#">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                            <h3><a href="#">The security risks of changing package owners</a></h3>
                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post-item">
                        <div class="post-image">
                            <a href="#">
                                <img src="../../../assets/img/blog-image/2.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta">
                                <li><a href="#">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                            <h3><a href="#">Tips to Protecting Your Business and Family</a></h3>

                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-blog-post-item">
                        <div class="post-image">
                            <a href="#">
                                <img src="../../../assets/img/blog-image/3.jpg" alt="image">
                            </a>
                        </div>

                        <div class="post-content">
                            <ul class="post-meta">
                                <li><a href="#">Admin</a></li>
                                <li>August 15, 2019</li>
                            </ul>
                            <h3><a href="#">Protect Your Workplace from Cyber Attacks</a></h3>

                            <a href="#" class="read-more-btn">Read More <i data-feather="plus-circle"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
        <div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
        <div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
        <div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
    </section>
    <!-- End Blog Area -->
</template>

<script>
export default {
    name: 'Blog'
}
</script>