<template>
    <div>
        <!-- Start Page Title -->
        <div class="page-title-area">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <h2>Contact Us</h2>
					</div>
				</div>
			</div>
			
			<div class="shape1"><img src="../../../assets/img/shape1.png" alt="shape"></div>
			<div class="shape2 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
			<div class="shape3"><img src="../../../assets/img/shape3.svg" alt="shape"></div>
			<div class="shape4"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape5"><img src="../../../assets/img/shape5.png" alt="shape"></div>
			<div class="shape6 rotateme"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape7"><img src="../../../assets/img/shape4.svg" alt="shape"></div>
			<div class="shape8 rotateme"><img src="../../../assets/img/shape2.svg" alt="shape"></div>
		</div>
        <!-- End Page Title -->
		
        <!-- Start Contact Info Area -->
		<section class="contact-info-area ptb-80">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="mail"></feather>
							</div>
                            <h3>Mail Here</h3>
                            <p><a href="#">contact@technocravers.com</a></p>
						</div>
					</div>
					
                    <div class="col-lg-4 col-md-6 col-sm-6">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="map-pin"></feather>
							</div>
                            <h3>Visit Here</h3>
                            <p>F-23A, Second Floor, Milap Nagar, Uttam Nagar,New Delhi - 110059</p>
						</div>
					</div>
					
                    <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                        <div class="contact-info-box">
                            <div class="icon">
                                <feather type="phone"></feather>
							</div>
                            <h3>Call Here</h3>
                            <p><a href="#">+91 9910 456 981</a></p>
						</div>
					</div>
				</div>
			</div>
		</section>
        <!-- End Contact Info Area -->
		
        <!-- Start Contact Area -->
        <section class="contact-area ptb-80 pt-0">
            <div class="inner-cont">
                <div class="section-title">
                    <h2>Get In Touch With Us</h2>
                    <div class="bar"></div>
                    <p>Anything On your Mind. We’ll Be Glad To Assist You!</p>
				</div>
				
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <img src="../../../assets/img/why-choose-us-image/image-4.png" class="wow fadeInLeft" alt="image">
					</div>
					
                    <div class="col-lg-6 col-md-12">
                        <form @submit.prevent="formSubmit">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text"  v-model="name" class="form-control" required data-error="Please enter your name" placeholder="Name">
                                        <div class="help-block with-errors"></div>
									</div>
								</div>
								
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
										<input type="email" v-model="email" class="form-control" required data-error="Please enter your email" placeholder="Email">
                                        <div class="help-block with-errors"></div>
									</div>
								</div>
								
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
                                        <input type="text" v-model="phone" required data-error="Please enter your number" class="form-control" placeholder="Phone">
                                        <div class="help-block with-errors"></div>
									</div>
								</div>
								
                                <div class="col-lg-12 col-md-6">
                                    <div class="form-group">
										<input type="text" v-model="subject" class="form-control" required data-error="Please enter your subject" placeholder="Subject">
                                        <div class="help-block with-errors"></div>
									</div>
								</div>
								
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea class="form-control" v-model="message" cols="30" rows="5" required data-error="Write your message" placeholder="Your Message"></textarea>
                                        <div class="help-block with-errors"></div>
									</div>
								</div>
								
                                <div class="col-lg-12 col-md-12">
                                    <button type="submit"  v-if="!isSuccess" id="submit" name="submit" class="btn btn-primary">Send Message</button>
                                    <div id="msgSubmit"  v-if="isSuccess" style="color:green" class="h4 text-center">{{output}}</div>
									<div id="msgSubmit1"  v-if="isFailure" style="color:red" class="h4 text-center">{{output}}</div>
                                    <div class="clearfix"></div>
								</div>
							</div>
						</form>
						
					</div>
				</div>
			</div>
		</section>
        <!-- End Contact Area -->
	</div>
	
</template>
<script>
	export default {
		name: 'Contact',
		mounted() {
            
		},
        data() {
            return {
				name: '',
				email: '',
				phone: '',
				subject: '',
				message: '',
				output: '',
				isSuccess: false,
				isFailure: false,
			};
		},
        methods: {
            formSubmit(e) {
                e.preventDefault();
                let currentObj = this;
				let data = {
					name: this.name,
                    email: this.email,
					phone: this.phone,
					subject: this.subject,
					message: this.message,
				};
				
				this.$recaptcha("login").then(token => {
					data["g-recaptcha-response"] = token;
					
					this.axios.post("/formsubmit.php", data, {
						headers: {
							Accept: "application/json"
						}
					})
					.then(function (response) {
						currentObj.isSuccess = (response.data.type == "success") ? true : false;
						currentObj.isFailure = (response.data.type == "danger") ? true : false;
						currentObj.output = response.data.message;
					})
					.catch(function (error) {
						currentObj.output = error;
					});
				});
			}
		}
	}
</script>